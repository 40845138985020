<template>
  <div>
   <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                    
    <pressChart1 class="hchart" v-bind:chartData="chartData"  v-bind:sdate="sdate" 
       v-if="loaded"    v-bind:chart_type ="chart_type" v-bind:ytitle="ytitle"   ></pressChart1>
  </div>
</template>

<script>
import pressChart1 from '@/components/chart/pressChart1' ;
import * as d3 from 'd3'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'pressChartHammer',
    props: [
        'height',
        'url'
    ],
  watch: {
        chartData: function() {

              //   deep: true,
                   this.redraw();
                // handler(){this.renderChart(this.chartData, this.options); }
        },
        url() {
            if(this.url==='') return
           // datacollection: null,
           
           this.chart_type = 'line'; 
          //this.chartData =  [];
       
         
          this.loaded=false;
           this.loaded_ts_flow=false;
      //    this.url = "http://localhost:3000/api/get_leak_bubble?w_idx=768" ;

         var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S.%L");
        
          //total_size : 전체사이즈 갯수, interval : 60초
         this.total_size = 0;
         this.interval = 0 ;
         var url_a =this.url.split('#');
         if(url_a.length >1){
              var vchart = url_a[1].split("_") ; //vchart[0]:               
              this.total_size = vchart[0]
              this.interval = vchart[1]
         }
         this.$http.get(this.url).then((res) => {
            //데이터 셋팅
              var b_data = res.data.data;
              
           
             if( b_data !=="" && b_data != null) {
                //var b_data = res.data;
                //this.chartData = res.data.data    
                //this.chartData = res.data           
                
                 var ccdata = [];
                 var size = b_data.length 
                 //var c_intrl = Number(this.interval)                
                 if(size < this.total_size){
                    size = this.total_size ;
                 }
                 
                  for(let i=0; i < size ; ++i){


                 
                          var ssdate = parseTime(b_data[i].date);
                          var sdate = Date.UTC(ssdate.getFullYear(), ssdate.getMonth(),  ssdate.getDate(),ssdate.getHours(),ssdate.getMinutes(),ssdate.getSeconds(),ssdate.getMilliseconds());
                          ccdata.push([sdate,Number(b_data[i].press_data)]);
                         // this.param[b_data[i].date] = b_data[i].press_data ;
                 
                  }
                  this.chartData = ccdata
               
                 
                
              
             }else{

                 this.xdata = [];
                 this.chartData = [] ;
                 
             }
          })
          .catch((error) => {
           // this.loaded = true;
            this.xdata = [];
            this.chartData = [] ;           
            this.loaded_ts_flow = true
            console.log(error)
          });


        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        
        loaded_ts_flow: true,
        chartData:  [0,1,2,3,4,5],
        //interval : 100,
        sdate: Date.UTC(2020, 0, 1,0,0,0,0),
        loaded: false,
        total_size : 0,
        bar_idx:-1,
        interval : 60, //60초
        param:{},
        ytitle : "Pressure",
      }
    },
    methods: {

       redraw () {

              this.loaded = true;
              this.loaded_ts_flow = true
            //  this.$emit('set_param', this.param) ;
            //  this.update();
           //   this.$data._chart.update()
           // this.chartData =  cData ;
            //this.$refs.chart.update();

      },
  
  },
  mounted () {
        window.addEventListener('resize', this.resize);

        if(this.url==='') return

         //this.$http.get(this.url).then((res) => {
            //데이터 셋팅
         //    if( res.data !=="" && res.data != null) {
         //       this.chartData = res.data.data;
                /*
                for(let i=0; i < b_data.length ; ++i){
                  this.xdata.push(b_data[i].w_title);
                  //this.chartData1.push(b_data[i].leak_count) ;
                  //this.chartData2.push(b_data[i].leak_inside) ;
                  this.chartData1.push(10) ;
                  this.chartData2.push(20) ;
                  this.chartData3.push(b_data[i].other_count) ;

                }
                */
      //       }else{
      //            this.xdata = [];
                 
      //       }
      //    });


    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.resize)
    },

    components: {
        PulseLoader: PulseLoader,
        pressChart1:pressChart1,
    }
}
</script>
<style lang="scss" > 
.hchart .highcharts-container{
    width: 100%;    
   /* margin-top:-30px; */
}
.presschart_m2_h .wavchart.hchart .highcharts-container{
    width: 100%;    
    margin-top:-30px;
}
.wavchart .highcharts-container .highcharts-root {
    width: 100%;    
    margin: 0 auto;
}



</style>
<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                      <li class="breadcrumb-item"><router-link to="/water-pressure">{{$t('pressure.title')}}</router-link></li>
                      <li class="breadcrumb-item active">{{$t('pressure.specification')}}</li>
                    </ol>
                </li>
            </ul>
        </div>
        <!-- tom-bar-end -->
    <search-left-type3 :box="box" :activeCheckIndex="activeCheckIndex" @updateCheckIndex="updateCheckIndex" 
     :visible_sort_box_press="true" :display_day="false" @go_search="go_search" @on_goto_map_click = "on_goto_map_click"/>
    
    <div class="content-area row justify-content-around" style="padding:0 5px;">
    <div class="left" style="padding-right:10px;">
          <div class="lk-dt-tab table-button-group">
          <!--b-button variant="lk-middle lk-btn-left" @click="set_30m_calculate()"><i class="mdi mdi-calculator mr-2"></i>{{$t('pressure.btn_30m_avg')}}</b-button-->          
        <!--  <b-button variant="lk-middle border-left-line" @click="$refs.leak_legend.show('set_leak_legend', $store.state.leak_legend);"><i class="mdi mdi-book-open mr-2"></i>LEGEND SETTING</b-button>-->
          <b-button variant="lk-middle border-left-line" @click="wthfile_downloads()"><i class="mdi mdi-download mr-2"></i>{{$t('pressure.btn_psr_down')}}</b-button>
          
          <b-button variant="lk-middle border-left-line"  @click="excel_download"   >
                 <download-excel  class   = "btn-default"                     
                        :data   = "json_data"
                        :fields = "json_fields"
                        worksheet = "My Worksheet"
                        :name    = "filename">
                    <i class="mdi mdi-download mr-2">{{$t('pressure.btn_excel_down')}}</i> 
                  </download-excel> 
           </b-button> 
           <b-button variant="lk-middle lk-btn-right border-left-linelk-middle border-left-line" @click="go_delete()"><i class="mdi mdi-delete-forever mr-2"></i>{{$t('pressure.btn_del')}}</b-button>                    
        </div>  
              <div class="info_box leak">
                  <ul class="list">
                      <li> Point Name : {{tag_name}} /Device No: {{t_device_no}}   </li>
                      <li>  {{$t('pressure.start_time')}} : {{t_start_time}} / {{$t('pressure.end_time')}} : {{t_end_time}} </li>
                      <li>{{$t('pressure.max_u')}} : {{t_max}} /{{$t('pressure.avg_u')}} : {{t_avg}} / {{$t('pressure.min_u')}} : {{t_min}}  </li>
                  </ul>
                </div>
     
  <!--
      <div class="input_search_box">
        <input type="text" class="input_search" v-model="value" placeholder="Search..."  @keyup.enter ="Enter_Check" >
        <input type="submit" class="input_submit"  @click="go_search" >
      </div>
    -->  
    <div class="list_box list_box2">
            <div class="page_list">
            <b-row class="list_box list_box2 press" >
            <b-col class="page_list">
          
                <h3>Pressure Monitoring Event Data</h3>
                <div class="table-box" >
                <!--<label for="textarea-small" class="table-box-text mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label>
                <vue-good-table ref="table"
                    :columns="columns"
                    :rows="table"
                    :line-numbers="true"
                    :max-height="table_height+'px'"
                    :select="true"
                    @on-row-click="onRowClick"

                >

                      <template slot="table-row" slot-scope="props" >
                        <b-button size="ss" variant="outline-danger" v-if="props.column.field == 'go_map'" @click="on_goto_map_click(props.row.idx)"><i class="fas fa-arrow-left"></i></b-button>
                        <b-button size="ss" variant="outline-info" v-else-if="props.column.field == 'info'" @click="$emit('on_info_click', props.row.idx)" ><i class="fas fa-info"></i></b-button>
                    </template>
                </vue-good-table>     

                <div style="position:absolute; top: 10px; right:10px;  ">
                 
                    <b-button v-if="levelA" class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text"   @click="go_delete()">delete</b-button>     
                 </div> -->
                    
                <ServerSideTable ref="table_press_info"
                                 :columns="columns"
                                 :search="false"
                                 :sort="true"
                                 :select="true"
                                 :add_custom_column="true"
                                 :max_height="table_height+'px'"
                                 @on-row-click="onRowClick"
                                 @on_goto_map_click="on_goto_map_click"
                                 @on_info_click="on_press_info"
                />
                  
                 </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div class="right" >
          <b-row style="padding: 10px 0px 0px;margin-bottom:5px;">
              <b-col  class="pressure-detail-content">
                <b-row >
                  <b-col style="padding-top: 0px; " >
                    <div class="status leak">
                      <input type="radio" id="radio_01" name="radio01"  v-model="vchart" value="kgf">
                      <label for="radio_01">kgf</label>
                      <input type="radio" id="radio_02" name="radio01"  checked v-model="vchart" value="bar">
                      <label for="radio_02">bar</label>
                      <input type="radio" id="radio_03" name="radio01"   v-model="vchart" value="psi" >
                      <label for="radio_03">psi</label>
                    </div>
                  </b-col>
                </b-row>
                 <button  class="btn-primary btn-g" style="  position: absolute;width:70px; margin: -9em 0.5em 0px -10px;" type="button" @click="goRouter('/pressHammer-detail-chart')" >
                 {{$t('pressure_monitoring.btn_chart')}}</button>
                  <div ref="pressure_chart" :style="{height: (map_height-70)+'px'}">
                   
                  <!--div ref="pressure_chart" -->
                    
                    <pressChartData1 class="surge_chart" :url="bar_url" :height=330  style="width:100%; height: 330px;"  />
                    
                     <!--p>     <pressRealTime :url="bar_url"  style="width:98%;height:400px;"   /></p>
                      <p>      <PressBigChartData :url="bar_url"  style="width:98%;"   /></p-->  
                  </div>
            
              </b-col>
          </b-row>
          <b-row style="padding: 10px 0px 0px;">
            <b-col class="pressure-detail-content-map">
               <div class="search_div_press">
                <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
                <span style="float:left; margin-left:10px;"> <b-select size="sm" v-model="pressure_value_type" :options="pressure_value_opt" change></b-select> </span>
              </div>
              <div :style="{height: map_height+'px'}">
                <Map ref="map"
                     :visible_full_extent="true"
                     :visible_gisload_button="true"
                     :widx="$route.query.widx"
                     :press_icon ="true"
                     :press_icon1 ="true"
                     w_type="111"
                     @set_map_gisload="set_map_gisload"
                     @toggle_visible_Hydraulic_type = "toggle_visible_Hydraulic_type"
                     @facility_invisible_layer = "facility_invisible_layer"
                     @leaktable_refresh="pressure_table_refresh"
                     @set_info="set_info"
                     @go_home="go_home()"
                />
              </div>
            </b-col>
          </b-row>
       
        </div>
      </div>
    </div>
</template>

<script>
import Map from '@/components/Map';
import * as d3 from 'd3'
import {CreateVectorLayer_f} from '@/gis.js';
import ServerSideTable from '@/components/table/ServerSideTable';
import pressChartData1 from '@/components/chart/pressChartHammer' ;
//import pressChartData1 from '@/components/pressChartData1' ;
import SearchLeftType3 from '@/components/left/SearchLeftType3_p';



export default {
    name: 'PressureDetail',
    props: {
    },
    watch: {
      ave_save_result(){
         ++this.roof_idx
         this.call_save_data(this.roof_idx)
      },
      psr_download_idx(){
        if(this.psr_download_idx > this.psr_download_arr.length -1 ) return
        var idx = this.psr_download_arr[this.psr_download_idx].file_id+"?retType=bar&sensor=" ;
       // var fname =  this.psr_download_arr[this.psr_download_idx].id+"_"+this.psr_download_arr[this.psr_download_idx].psr_file_name ;
        var fname =  this.psr_download_arr[this.psr_download_idx].file_id+"_"+this.psr_download_arr[this.psr_download_idx].start_time ;
        this.psrfile_download(idx, fname)
      },
      vchart() {
       
          var url =  this.bar_url 
          var surl =  url.split('&retType=')
          this.bar_url  = surl[0]+'&retType='+this.vchart          

      },
      pressure_value_type(){
        this.chang_press_mapvalue_type() ;
      }
    },
    data() {
        return {
            contents_height: 800,
            map_height: 250,
            table_height: 520,
            table: [],
            tag_name : "",
            tag: -1 ,
            value:'',
            addr_value :'',
            w_title : '',
            vchart : 'bar',
            bar_url : '',
            levelA : false,
            chart_data : [],
            change_data :[],
            check_idx:-1,
            setting: null,
            pressure_value_type : '',
            activeCheckIndex : 0,  
            pressure_value_opt: ['avg' , 'min', 'max' ],
            ave_save_result : null,
            roof_idx : 0,
            psr_download_idx: -1,
            psr_download_arr: [],
            p_idx_arr : [],
            filename : 'filename.xls',
            columns: [
              //  {label: 'No', field: 'idx'},
               // {label: this.$t('pressure.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                {label: this.$t('pressure.table_column.info'), field: 'info', width: '10%', sortable: false},
              //  {label: 'Worke No', field: 'w_idx'},
             //   {label: 'Facility No', field: 'f_idx'},

                {label: this.$t('pressure.start_time'), field: 'start_time',width: '22%',},
                {label: this.$t('pressure.end_time'), field: 'end_time',width: '22%',},
              //  {label: this.$t('pressure.point_name'), field: 'point_name',width: '13%',},       
              //  {label: this.$t('pressure.device_no'), field: 'device_sn',width: '13%',},
              // {label: 'Facility', field: 'layer_type',sortable: false}, 
              //  {label: 'Time Interval(ss)', field: 'period',sortable: false},                
              //  {label: this.$t('pressure.saved_time'), field: 'save_time',width: '8%',},
              //  {label: this.$t('pressure.time_intr'), field: 'time_interval',width: '8%',},
                {label: this.$t('pressure.max_u'), field: 'max',width: '15%',},
                {label: this.$t('pressure.min_u'), field: 'min',width: '15%',},
                {label: this.$t('pressure.avg_u'), field: 'avg',width: '15%',},
               // {label: this.$t('pressure.elevation'), field: 'elevation',width: '8%',},
             
               // {label: 'Remarks', field: 'remarks'}
            ],
              t_start_time : '',
              t_end_time : '',
              t_worker : '',
              t_device_no : '',
              t_save_time : '',
              t_time_interval : '',
              t_f_type: '  ',
              t_remark: '  ',
              t_elevation: '',
              t_max: '',
              t_avg: '',
              t_min: '',
              json_data :[],
              box : [] ,
            json_fields:{
              'ID':'id',
              'Work No': 'water_work_id',
             // 'Worker': 'm_id',             
              'Start Time': 'measure_start_datetime',
              'Point Name' : 'point_name',
              'Device No' : 'device_sn',
              'Facility No': 'facility_id',
              'Save Time(h)':'save_time',    
              'Time Interval(s)': 'time_interval',
              'Max(bar)' : 'max',
              'Min(bar)' : 'min',
              'Avg(bar)': 'avg',   
              'Elevation':'elevation',
              //'X' : 'X',
              //'Y' : 'Y',        
              'Remarks': 'remarks'
            },
            map_path:'/pressure-detail-map'
        }
    },
    methods: {
      goRouter(path) {
        var param = { tag: this.tag,tag_name: this.tag_name, 
                   device_sn: this.t_device_no, file_id: this.box[this.activeCheckIndex].file_id  }  
        this.$router.push({path: path, query: param})
      },
      set_30m_calculate(){
          if(this.map_path === "/pressure-detail-map"){ 
            alert(this.$t('pressure.alt_no_make_data'));
            return;
          }
          let rows = this.$refs.table_press_info.getSelectRows()
          if(rows.length<=0) {
             alert(this.$t('leak_detail.alt_select'));
            return;
          }
          this.p_idx_arr = []
          this.roof_idx = 0 ;
          for(let i=0; i<rows.length; i++) this.p_idx_arr.push(rows[i].id)
          this.call_save_data(this.roof_idx)      
        },
        call_save_data(idx){
          if(idx >= this.p_idx_arr.length) return
          this.$http.get(this.URL+'api/set_psr_30m_avg?idx='+this.p_idx_arr[idx]).then((res) => {
             if(res.data.check ===1){ 
                this.roof_idx = 0 ;
                this.p_idx_arr = [];               
                alert(this.$t('pressure.alt_not_save'));
                return
             }     
             //alert(idx + " : Data has been successfully saved on the server.  ")
             var con_test = confirm(idx + this.$t('pressure.conf_pro'));
             if(con_test == true){
                if(idx >= this.p_idx_arr.length-1){
                  alert(this.$t('pressure.alt_done'))
                  return
                } 
                this.ave_save_result = res.data.p_idx ;  
              }
              else if(con_test == false){
                this.p_idx_arr = []
                this.roof_idx = 0 ;
                this.$refs.table_press_info.getSelectRows()
              }
             
         })
        },  
        window_resiz() {
            this.contents_height = window.innerHeight - 80
            this.map_height = this.contents_height/2 - 30
          //  this.map_height = this.table_height

            this.$refs.map.update_size()
        },
        onTitleChange(row){
          this.t_start_time = row.start_time ;
          
               
          this.t_end_time = row.end_time ;           
          
          //this.t_save_time = row.save_time ; 
          //this.t_time_interval =row.time_interval ;  

          //this.t_elevation= row.elevation ; 
          this.t_max= row.max ; 
          this.t_avg= row.avg ; 
          this.t_min= row.min ; 
       },
        chang_press_mapvalue_type() {
          // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          
          this.$refs.map.leak_cal = this.pressure_value_type;         
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;

        },
        toggle_visible_Hydraulic_type() {
          // 처음엔 동수도 적용안한다. Hydraulic_type 데이터를 가져와 지도에 보여준다. 동수도 적용안한다.
          // All or Hydraulic 적용값 가져와 지도에 보여준다.
          this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
          if(this.$refs.map.layer_leak_visible)
            this.$refs.map.leak_type = 'HYDRAULIC';
          else
            this.$refs.map.leak_type = null;
          
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;      
        },    
       facility_invisible_layer() {

          this.layer_all_visible = false;
          let map = this.$refs.map.map
          for(let i=1; i<map.getLayers().getLength(); i++) {
            let layer = map.getLayers().item(i)
            if(layer.get('code')>50) continue;
            layer.setVisible(this.layer_all_visible)
          }
        },
        addr_search(){
          this.$refs.map.go_search(this.addr_value);
        },
        Enter_Check1(){
          this.addr_search();
        },        
        wthfile_downloads() {
           
            this.psr_download_arr = this.$refs.table_press_info.getSelectRows()
            if( this.psr_download_arr.length<=0){
               alert(this.$t('leak_detail.alt_select'));   
               this.psr_download_arr=[];
              return;  
            }else{
              this.psr_download_idx = 0;
            }   
        },
        psrfile_download(idx, name) {
             // this.$http.get(this.URL+'api/'+idx).then((response) => {
              
              this.$http({
               // url: this.URL+'api/download/psr/'+idx,
               ///api/API21/FILE/575c5303-b633-40c2-858b-b9e54ec865be?outType=psrToJson
                url: this.URL+'api/m2/'+idx,
                method: 'GET',
                //responseType: 'blob',
                //data: JSON.stringify(data),
                responseType: 'json',
                contentType: 'application/json; charset=utf-8',
              }).then((response) => {
                 //let data = JSON.parse(decodeURIComponent(response.data))
                 //let json_data = JSON.stringify(response.data) ;
                 let data = this.jsonToCSV(response.data.data)
                var fileURL = window.URL.createObjectURL(new Blob([data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', name+".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                setTimeout(()=>{
                  ++this.psr_download_idx
                }, 200);
          
              })

        },
        jsonToCSV(json_data) { // 1-1. json 데이터 취득
          const json_array = json_data; // 1-2. json데이터를 문자열(string)로 넣은 경우, JSON 배열 객체로 만들기 위해 아래 코드 사용 
          
          let csv_string = ''; // 3. 제목 추출: json_array의 첫번째 요소(객체)에서 제목(머릿글)으로 사용할 키값을 추출
          //const titles = Object.keys(json_array[0]); // 4. CSV문자열에 제목 삽입: 각 제목은 컴마로 구분, 마지막 제목은 줄바꿈 추가 
          //titles.forEach((title, index)=>{ csv_string += (index !== titles.length-1 ? `${title},` : `${title}\r\n`); }); 
          csv_string += 'date, Pressure(bar)\r\n';
          json_array.forEach((content, index)=>{ 
          let row = '';
          // 각 인덱스에 해당하는 '내용'을 담을 행
          for(let title in content){ // for in 문은 객체의 키값만 추출하여 순회함.
              // 행에 '내용' 할당: 각 내용 앞에 컴마를 삽입하여 구분, 첫번째 내용은 앞에 컴마X
              row += (row === '' ? `${content[title]}` : `,${content[title]}`); } 
              // CSV 문자열에 '내용' 행 삽입: 뒤에 줄바꿈(\r\n) 추가, 마지막 행은 줄바꿈X 
              csv_string += (index !== json_array.length-1 ? `${row}\r\n`: `${row}`); }) 
              // 6. CSV 문자열 반환: 최종 결과물(string) 
          return csv_string;
        },

          
        printDate() {
           var temp = new Date();
           var dateStr = this.padStr(temp.getFullYear()) +
                         this.padStr(1 + temp.getMonth()) +
                         this.padStr(temp.getDate()) +
                         this.padStr(temp.getHours()) +
                         this.padStr(temp.getMinutes()) +
                         this.padStr(temp.getSeconds());
            return dateStr;
        },
        padStr(i) {
            return (i < 10) ? "0" + i : "" + i;
        } ,   
        excel_download() {         
          this.filename = this.printDate()+"_"+this.$route.query.widx+".xls" ;
          //this.filename = this.filename.replace(",","_")
          let rows = this.$refs.table_press_info.rows ;
          if(rows.length<=0) {
            alert(this.$t('leak_detail.alt_no_data'));
            return;
          }
          this.json_data = rows ;
          // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
          // this.$router.push({path: '/leak-detail', query: { widx: list }})
        },   
        go_search() {
          var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
          if(this.value===undefined) return;

            this.$refs.table_press_info.setUrl(this.URL+"api/get_press_list_ex?w_idx="+this.$route.query.widx+'&search='+this.value, (rows) => {
              if((!rows)||(rows.length<=0)) return;
            //  idx = rows[0].idx;

          })
          this.$http.get(this.URL+'api/get_press_list_ex?w_idx='+this.$route.query.widx).then((res) => {
             if(res.data.length<=0) return
                //
                
             this.check_idx = res.data.list[0].idx;
             this.onTitleChange(res.data.list[0]) ;
             for(let i=0; i<res.data.list[0].data.length; i++) {
               res.data.list[0].data[i].date = parseTime(res.data.list[0].data[i].date)
             }
             this.chart_data = res.data.list[0].data ;  
          //    this.draw_pressure_chart(res.data.list[0].data)
         })
     },        
    Enter_Check(){
      //if(event.keyCode == 13){
      this.go_search();  // 실행할 이벤트
    },
    pressure_table_refresh(){
      this.$refs.table_press_info.setUrl(this.URL+"api/get_press_list_ex?w_idx="+this.$route.query.widx, (rows) => {
        if((!rows)||(rows.length<=0)) return;      
         this.bar_url = this.URL+'api/'+ rows[0].psr_file_path+'&sensor='+ rows[0].sensor+'&retType='+this.vchart 
        
      })
    },
       on_goto_map_click(row) {
            this.$refs.map.get_extent('w_type=110&p_idx='+row.id+"&w_idx="+row.water_work_id).then((data)=>{
                this.$refs.map.set_fit_extent(data)
                if(this.$store.setting.map_name==='Daum')
                 this.$refs.map.map.getView().setZoom(13);
                else
                   this.$refs.map.map.getView().setZoom(18);
               // this.$refs.map.map.getView().setZoom(13);
                this.$refs.map.select_facility_feature('110','',row.id)  
            })
        },
        onRowClick(params) {
          if(this.clicked_b_row != null){
              this.clicked_b_row.bgColor = '';
          //  //  this.clicked_b_row.style.backgroundColor = '';
          }
          
            if(params.event.target.nodeName==='TD'){
                params.event.target.parentElement.bgColor  = '#125744';          
                this.clicked_b_row = params.event.target.parentElement ;
            }else  if(params.event.target.nodeName==='IMG'){
                params.event.target.parentElement.parentElement.parentElement.bgColor = '#125744';      
                this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
            }else{
                params.event.target.parentElement.parentElement.bgColor = '#125744';      
                this.clicked_b_row = params.event.target.parentElement.parentElement ;
            }
      
            //this.draw_pressure_chart(this.table[params.row.originalIndex].data)
            let idx = params.row.id
           
             this.bar_url = this.URL+'api/m2/'+ params.row.file_id+'?sensor=&retType='+this.vchart 
       
            this.check_idx = idx;
            this.onTitleChange(params.row) ;

        },
         set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length-1; i++) {
                    var layer = this.$refs.map.layer_list[i];
                    if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length-1; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);


                    } //for문 끝

                } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },
       go_delete() {
            if(sessionStorage === null || typeof sessionStorage === undefined){
               alert(this.$t('leak_detail.alt_no_update'))
               return
         //   }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 2){
               alert(this.$t('water_leak.alert_no_permission'))
               return;
            }         
            let rows = this.$refs.table_press_info.getSelectRows()
            if(rows.length<=0) {
              alert(this.$t('leak_detail.alt_no_data'));
              return;
            }

            let list = []
            for(let i=0; i<rows.length; i++){        
              list.push(rows[i].id)
            }
            var con_test = confirm(this.$t('water_leak.left_menu.confirm_msg_delete'));
            //let list = idx
            // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
            if(con_test){
              this.$http.get(this.URL+'api/delete_ex?p_idx_ex='+list).then((res) => {  
                console.log(res.data);
                  this.pressure_table_refresh();
                });

            }
        },
        on_press_info(row) {
           this.$refs.map.show_press_info(row.id)
        },
        go_home() {
         //  this.$router.push({path: '/', query: { widx: this.$route.query.widx, w_type: '110' }})
           
           this.$router.push({path: this.map_path, query: { widx: this.$route.query.widx, w_type: '110' }})
        },
        presstable_refresh(){
          this.$refs.table_leakage_info.setUrl(this.URL+"api/m2/fileList/"+this.$route.query.widx, (rows) => {
            if((!rows)||(rows.length<=0)) return;
               this.bar_url = this.URL+'api/m2/'+ rows[0].file_id+'?sensor=&retType='+this.vchart 
          })
        },
        updateCheckIndex(payload) {
          this.activeCheckIndex = payload 
          this.tag=this.box[payload].id
          this.tag_name = this.box[payload].name
          this.t_device_no = this.box[payload].device_sn ; 
          this.press_event_list();
        },
        press_event_list(){
          this.$refs.table_press_info.setUrl(this.URL+"api/m2/fileList/"+this.tag, (rows) => {
              if((!rows)||(rows.length<=0)) return;
                
              this.bar_url = this.URL+'api/m2/'+ rows[0].file_id+'?sensor=&retType='+this.vchart 
              this.onTitleChange(rows[0]) ;
          })
        }
        
    },
    created () {
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.window_resiz)
    },
    mounted () {
        window.addEventListener('resize', this.window_resiz)
        this.window_resiz()
        var customer_id = sessionStorage.select_customer_id ; 
        //var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
        setTimeout(()=>{
          let setting = this.$store.setting
          if(setting) {
               // this.pressure_value_type = this.$store.setting.pressure.legend.value
          }
        }, 1000)
        //let idx = -1;
        if(sessionStorage !== null && typeof (sessionStorage) !== undefined && sessionStorage.level < 2){
          this.levelA = true       
        }else{
          this.levelA = false
        }
        this.$http.get(this.URL+'api/m2/hammer/list?customer_id='+customer_id+"&status=A").then((res) => { 
          if(res.data.rows.length <1) return
        
          for(let i=0; i<res.data.rows.length; i++) {
          
            var abox = {};
     
          //  abox = {'name': rows.data.rows[i].name, 'mnf': Math.round(Number(rows.data.rows[i].mnf)) , 'flow':  Math.round(Number(rows.data.rows[i].flow)) ,  'status': 'blue' } ; 
            var press_data = res.data.rows[i].water_pressure === undefined || res.data.rows[i].water_pressure === null  ? "" :  res.data.rows[i].water_pressure.toFixed(2)
            var last_date = res.data.rows[i].last_measure_datetime === null ? "" :  res.data.rows[i].last_measure_datetime
           // var comm_sense = res.data.results[0][i].modem_signal_sensitivity === null ? "5" :  res.data.results[0][i].modem_signal_sensitivity
            //var status = (res.data.list[i].content == null || res.data.list[i].content== undefined || res.data.results[0][i].content== 0) ? "" : "red"
            abox = {'name': res.data.rows[i].point_name, 'id': res.data.rows[i].id ,  'device_sn':res.data.rows[i].device_sn ,
                //   "mnp_boundary" : res.data.results[0][i].mnp_boundary,
                  'point_status' :res.data.rows[i].point_status,
                  'flow':  last_date+"/ p_data :  "+press_data , 
                  'date':  last_date ,
                  'status': status} ; 
            this.box.push(abox) ; 
           // if(select_id === res.data.list[i].id){
                // select_id = i; 
                          
           // }
          }
          
          setTimeout(()=>{
            var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
            if( elem !== undefined ){
              if(elem.length > 0){
                  document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();         
              }
            }
            this.updateCheckIndex(0)
          }, 500)   
        //  this.get_time_series_mnf()
        //  this.get_time_series_flow()
       })        

 
      
    },
    components: {
      //SearchLeftType2,
        SearchLeftType3,
        Map: Map,
        ServerSideTable: ServerSideTable,
       // pressRealTime:pressRealTime,
    //    PressBigChartData:PressBigChartData,
     
        pressChartData1:pressChartData1,
    }
}

</script>
<style lang="scss" >
.content-area {
  padding: 0 30px;
}
.pressure-detail-content {
  // 2023
  // background: #fff;
  // padding: 0 10px 10px;
  padding: 10px 30px 0;
  background: #172A46;
  border: 1px solid #172A46;
  color: #fff;
}
.right {
  .pressure-detail-content-map {
    padding: 0;
  }
}
.left .list_box.list_box2.press {
      min-height: 675px;
      height: calc(95vh - 50px - 70px - 20px - 50px - 30px );
}
.search_div_press{
    position: absolute;
    left:255px;
    top: 5px;
    z-index: 111;
}
.spn1 {
      float: left;
}
.surge_chart .wavchart.hchart {  
  height: 330px;
}

</style>
